import React from 'react'
import styled from 'styled-components'
import getTheme from '../../theme'

const StyledPaper = styled.div`
  background: #fff;
  border-radius: ${getTheme().spacing * 2}px;
`

const Paper = ({ children, ...rest }) =>
  <StyledPaper {...rest}>
    {children}
  </StyledPaper>

export default Paper
