import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'
import { transformExtent } from 'ol/proj'
import GermanyRough from '../../../../assets/germany_rough.json'

export const germanyRoughFormat = new GeoJSON({ featureProjection: 'EPSG:3857' })
export const germanyRoughFeatures = germanyRoughFormat.readFeatures(GermanyRough)
export const germanyRoughVectorSource = new VectorSource({ features: germanyRoughFeatures })
export const germanyRoughExtend = germanyRoughVectorSource.getExtent()

export const extent = transformExtent(
  germanyRoughExtend,
  'EPSG:4326', 'EPSG:3857'
)
