import isDefined from '../../../utils/is-defined'

export const getParams = path => path.split('/').filter(input => input !== '')
export const getParamList = params => params.match(/:(\w+):/g) || []

export const getParamsListFromLocation = ({ location, path }) => {
  const explodedLocation = getParams(location)
  const explodedPath = getParams(path)
  const params = explodedPath
    .reduce((result, current, index) => [...result, (current.match(/:(\w+):/g) && explodedLocation[index]) || null], [])
    .filter(isDefined)
  return params
}

export const getParamsFromLocation = ({ location, path }) => {
  const explodedLocation = getParams(location)
  const explodedPath = getParams(path)
  const params = explodedPath.reduce((result, current, index) => {
    const isParam = current.match(/:(\w+):/g)
    if (isParam) {
      const paramName = isParam[0].replace(/:/g, '')
      return { ...result, [paramName]: explodedLocation[index] }
    }
    return { ...result }
  }, {})
  return params
}

export const getPathWithParams = ({ location, path }) => {
  const paramsList = getParamsListFromLocation({ location, path })

  let result = path
  for (let i = 0; i < paramsList.length; i++) {
    result = result.replace(/:(\w+):/, paramsList[i])
  }
  return result
}

export const doesLocationMatchesPath = ({ location, path }) => {
  if (location === path) {
    return true
  }

  const explodedLocation = getParams(location)
  const explodedPath = getParams(path)

  const normalizedLocation = explodedPath
    .map((str, index) => {
      try {
        if (!str.match(/:(\w+):/g)) {
          return explodedLocation[index]
        }
        return null
      } catch (error) {
        return str
      }
    })
    .filter(isDefined)

  const pathWithoutParams = explodedPath.filter(str => !str.match(/:(\w+):/g))
  const hasPathNecessaryParams = explodedLocation.length === explodedPath.length
  const arePathParamsMet = pathWithoutParams.join('') === normalizedLocation.join('')

  return hasPathNecessaryParams && arePathParamsMet
}

export const doesLocationMatchesSubpath = ({ location, path }) => {
  const pathWithParams = getPathWithParams({ location, path })
  return !!location.match(new RegExp(`^${pathWithParams}`))
}
