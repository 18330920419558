import { Folder } from '@styled-icons/material/Folder'
import { FileBlank } from '@styled-icons/boxicons-regular/FileBlank'
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight'
import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown'
import { ChevronLeft } from '@styled-icons/boxicons-regular/ChevronLeft'
import { ChevronUp } from '@styled-icons/boxicons-regular/ChevronUp'
import { ErrorCircle } from '@styled-icons/boxicons-regular/ErrorCircle'
import { Dot } from '@styled-icons/bootstrap/Dot'
import { PlayFill } from '@styled-icons/bootstrap/PlayFill'
import { PauseFill } from '@styled-icons/bootstrap/PauseFill'
import { Reset } from '@styled-icons/boxicons-regular/Reset'
import { Bug } from '@styled-icons/boxicons-regular/Bug'
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle'
import { Edit } from '@styled-icons/boxicons-solid/Edit'
import { Asterisk } from '@styled-icons/bootstrap/Asterisk'
import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal'
import styled from 'styled-components'
import getTheme from '../../theme'

const style = `
width: ${getTheme().fontSize}px;
min-width: ${getTheme().fontSize}px;
`

export const FolderIcon = styled(Folder)`
  ${style}
`
export const FileIcon = styled(FileBlank)`
  ${style}
`
export const ChevronRightIcon = styled(ChevronRight)`
  ${style}
`
export const ChevronLeftIcon = styled(ChevronLeft)`
  ${style}
`
export const ChevronDownIcon = styled(ChevronDown)`
  ${style}
`
export const ChevronUpIcon = styled(ChevronUp)`
  ${style}
`
export const ErrorCircleIcon = styled(ErrorCircle)`
  ${style}
`
export const DotIcon = styled(Dot)`
  ${style}
`
export const PlayFillIcon = styled(PlayFill)`
  ${style}
`
export const PauseFillIcon = styled(PauseFill)`
  ${style}
`
export const ResetIcon = styled(Reset)`
  ${style}
`
export const BugIcon = styled(Bug)`
  ${style}
`
export const InfoCircleIcon = styled(InfoCircle)`
  ${style}
`
export const EditIcon = styled(Edit)`
  ${style}
`
export const AsteriskIcon = styled(Asterisk)`
  ${style}
`
export const LinkExternalIcon = styled(LinkExternal)`
  ${style}
`
