import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../../lib/context/AppContext'
import getTheme from '../../../lib/theme'
import isDefined from '../../../lib/utils/is-defined'
import moment from 'moment'

const StyledLiveIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: ${getTheme().spacing}px;
  font-size: ${getTheme().fontSize * 1.5}px;
  color: #6E6E6E;
`
const LiveIndicator = ({ style }) => {
  const { energyMix } = useAppContext()
  const [time, setTime] = useState(null)

  useEffect(() => {
    if (!isDefined(energyMix)) {
      return
    }

    const date = energyMix.date
    const dateMoment = moment(date)
    const timeMoment = dateMoment.format('HH:mm')
    setTime(timeMoment)
  }, [energyMix])

  console.log(energyMix)

  return <StyledLiveIndicator {...{ style }} >
    {isDefined(time) && <span className="text">Datenstand {time} Uhr</span>}
  </StyledLiveIndicator>
}

export default LiveIndicator
