import React, { createContext, useContext, useState } from 'react'

const AppContext = createContext()

export function AppWrapper ({ children }) {
  const [currentApi, setCurrentApi] = useState(null)
  const [currentStage, setCurrentStage] = useState(null)
  const [currentCounty, setCurrentCounty] = useState(false)
  const [energyMix, setEnergyMix] = useState(null)
  const [currentForecast, setCurrentForecast] = useState([])
  const [desktopFooterHeight, setDesktopFooterHeight] = useState(null)

  const sharedState = {
    currentApi,
    setCurrentApi,
    currentCounty,
    setCurrentCounty,
    energyMix,
    setEnergyMix,
    currentForecast,
    setCurrentForecast,
    desktopFooterHeight,
    setDesktopFooterHeight,
    currentStage,
    setCurrentStage
  }

  return <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
}

export function useAppContext () {
  return useContext(AppContext)
}
