import clsx from 'clsx'
import React from 'react'
import styled from 'styled-components'
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory'
import StatusIndicator from '../../../src/Common/StatusIndicator'
import getTheme from '../../theme'

const ticks = new Array(24).fill(null).map((_, i) => `${i}`.padStart(2, 0))
const tempData = new Array(24).fill(null).map((_, i) => ({ x: i + 1, y: 0 }))
const currentTime = `${new Date(Date.now()).getHours()}`.padStart(2, 0)

const StyledBarChart = styled.div`
position: relative;

.statusbar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: background 0.3s ease-in-out;
  background: #FAFAFA00;

  &.loading {
    background: #FAFAFACC;
  }
  &.error {
    background: #FAFAFACC;
  }
}

`
const BarChart = ({ data, status }) => {
  if (!data.length) {
    data = tempData
  }

  return <StyledBarChart>
  <VictoryChart domainPadding={ getTheme().spacing } allowZoom={false} height={220}>
    <VictoryBar
      style={{
        data: {
          fill: ({ data, index }) => {
            return data[index].y > 60 ? '#A6CF7E' : '#0091BB'
          },
          opacity: ({ data, index }) => {
            return data[index].x <= currentTime ? 0.35 : 1
          },
          width: getTheme().spacing

        }
      }}
      cornerRadius={getTheme().spacing / 2}
      data={data}
      animate={{ duration: 300, onLoad: { duration: 300 } }}

    />
    <VictoryAxis
      crossAxis={false}
      tickValues={ticks}
      style={{
        ticks: { stroke: 'grey', size: getTheme().spacing / 2 },
        tickLabels: {
          fontSize: 10,
          padding: getTheme().spacing / 2,
          fill: ({ tick }) => tick === currentTime ? 'black' : '#ccc'
        },
        padding: 5
      }}
    />
    <VictoryAxis crossAxis={false}
      dependentAxis
      tickValues={[60, 100]}
      tickFormat={t => `${t} %`}
      style={{ grid: { stroke: ({ tick }) => tick === 60 ? '#ccc' : 'none' } }}
    />
  </VictoryChart>
  <div className={clsx('statusbar-container', status)}>
    <StatusIndicator status={status} style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}/>
  </div>
</StyledBarChart>
}

export default BarChart
