import React from 'react'
import appstore from '../../../assets/Appstore.svg'
import playstore from '../../../assets/google-play-badge 1.png'
import getTheme from '../../../lib/theme'
import Image from '../../../lib/components/Image'
import styled from 'styled-components'

const StyledAppStoreInfo = styled.div`
 .appstore-info-text{
   text-align: center;
   margin-bottom: ${getTheme().spacing * 2}px;
   font-size: ${getTheme().fontSize * 2.25}px;
 }
 
 .applinks{
   margin-left: 32px;
   margin-right: 32px;
   display: flex;
   justify-content: center;
   padding: 16px
   height:40px;
  }
  
  .contact-info-text{
    text-align: center;
    margin-bottom: ${getTheme().spacing * 3}px;
    font-size: ${getTheme().fontSize * 2.25}px;
  }
  
  .contact-button-container {
    display: flex;
    justify-content: center;
  }

  .contact-button {
    all: unset;
    text-align: center;
    cursor: pointer;
    font-size: ${getTheme().fontSize * 1.75};
    padding: ${getTheme().spacing}px ${getTheme().spacing * 3}px;
    background: ${getTheme().bag.blue2};
    border-radius: 100px;
    color: white;
    margin-bottom: ${getTheme().spacing * 3}px;
    font-family: 'Polo22Krftg';
  }
`

const AppStoreInfo = () => {
  return (<StyledAppStoreInfo>
  <div className="contact-info-text">Du bist interessiert an unseren Daten? Dann nimm Kontakt zu uns auf.</div>
  <div className="contact-button-container">
    <a href="mailto:oekoheld@bayernwerk.de?subject=Anfrage%20Oekoheld%20API" role="button" className="contact-button">Jetzt Kontakt aufnehmen</a>
  </div>
  <div className="appstore-info-text">Du willst die regionale Energiewende mitgestalten? Dann lade dir jetzt die ökoHeld App auf dein Smartphone.</div>
  <div className="applinks">
    <div className="logo-container"><a href="https://apps.apple.com/de/app/%C3%B6koheld/id1568297347" role="link" target="_blank" rel="noreferrer"><Image src={appstore}/></a></div>
    <div className="logo-container"><a href="https://play.google.com/store/apps/details?id=com.bayernwerk.oekoheld" role="link" target="_blank" rel="noreferrer"><Image src={playstore}/></a></div>
  </div>
  </StyledAppStoreInfo>)
}
export default AppStoreInfo
