import React from 'react'
import styled from 'styled-components'
import getTheme from '../../../lib/theme'
import { TailSpin } from 'react-loader-spinner'
import { ErrorCircleIcon } from '../../../lib/components/Icons'
import clsx from 'clsx'
import isDefined from '../../../lib/utils/is-defined'

const StyledMapLegend = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  
  .status-indicator-container {
    padding: ${getTheme().spacing}px;
    border-radius: ${getTheme().spacing}px;
    display: flex !important;

    ${({ text }) => {
      if (!isDefined(text)) {
        return ''
      }

      return `
      bottom: ${getTheme().spacing * 2}px;
      background: #fff;
      box-shadow: 1px 1px 3px #ddd;
      `
    }}

  }

  
  .information-paper {
    padding: ${getTheme().spacing}px;
    font-size: ${getTheme().fontSize * 1.5}px;
    display: flex;
    align-items: center;
  }

  .icon {
    align-self: flex-end;
  }

`
const MapLoadingIndicator = ({ mobile, status, style, text, ...rest }) => {
  return <StyledMapLegend className={clsx(isDefined(text) && 'col-xs-12 col-lg-4')} {...{ style }} {...{ mobile, text }}>
    <div className="status-indicator-container">
      {status === 'loading' && <TailSpin className="icon" {...{ width: 48, height: 48, color: getTheme().bag.blue, ariaLabel: 'loading' }}/>}
      {status === 'error' && <ErrorCircleIcon className="icon" {...{ style: { width: 48, height: 48, color: getTheme().bag.blue } }}/>}
      {isDefined(text) && <div className={clsx('information-paper')} >{text}</div>}
    </div>
  </StyledMapLegend>
}

export default MapLoadingIndicator
