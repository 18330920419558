import { withStateHandlers, compose, withContext, lifecycle } from 'recompose'
import { string, func, object } from 'prop-types'
import isString from '../../../utils/is-string'

const normalizeLocation = location => {
  if (isString(location)) {
    return location.replace('.*#', '')
  }
  return location
}

export default compose(
  withStateHandlers(
    () => ({
      location: normalizeLocation(window.location.hash),
      activeRoute: { path: null, location: null, params: {} }
    }),
    {
      navigate: ({ location }) => path => {
        window.history.pushState(null, '', `#/${path}`)
        document.querySelector('html').scrollTop = 0
        return { location: path }
      },
      setActiveRoute: () => activeRoute => {
        return { activeRoute }
      }
    }
  ),
  lifecycle({
    componentDidMount () {
      window.addEventListener('popstate', e => this.forceUpdate())
    }
  }),
  withContext(
    { location: string, navigate: func, activeRoute: object, setActiveRoute: func },
    ({ location, navigate, activeRoute, setActiveRoute }) => ({
      location: normalizeLocation(location),
      navigate,
      activeRoute,
      setActiveRoute
    })
  )
)(({ children }) => children)
