import React from 'react'
import Paper from '../../../lib/components/Paper'
import getTheme from '../../../lib/theme'
import localizeNumber from '../../../lib/utils/localize-number'
import styled from 'styled-components'

const StyledMapOverlay = styled(Paper)`
  .region-name {
    font-family: 'Polo22Krftg';
    font-size: ${getTheme().fontSize * 2}px;
  }
  .co2-values {
    font-family: 'Polo22Krftg';
    font-size: ${getTheme().fontSize * 4.125}px;
  }
  .co2-legend {
    font-family: 'Polo22Leicht';
    font-size: ${getTheme().fontSize * 1.5}px; 
  }
`

const MapOverlay = ({ currentCounty, ...rest }) => (
  <StyledMapOverlay {...rest} style={{ boxShadow: ' 2px 2px 10px rgba(0, 0, 0, 0.1)', maxWidth: '60%' }}>
  <div style={{ paddingBottom: 0 }} className="col-xs-12 region-name">{currentCounty && `${currentCounty.krs_name}`}</div>
  <div className="col-xs-6 no-padding">
    <div className="col-xs-12 center-text co2-values">{localizeNumber(currentCounty.energyMix, 0)}%</div>
    <div className="col-xs-12 center-text co2-legend" style={{ paddingTop: 0 }}>Verfügbare Erneuerbare Energien</div>
  </div>
  <div className="col-xs-6 no-padding">
    <div className="col-xs-12 center-text co2-values">{localizeNumber(currentCounty.co2, 0)}g</div>
    <div className="col-xs-12 center-text co2-legend" style={{ paddingTop: 0 }}>CO2 Emission pro kWh</div>
  </div>
  </StyledMapOverlay>
)

export default MapOverlay
