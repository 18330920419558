import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Style } from 'ol/style'
import World from '../../../../assets/world.json'

export const worldFormat = new GeoJSON({ featureProjection: 'EPSG:3857' })
export const worldFeatures = worldFormat.readFeatures(World)
export const worldVectorSource = new VectorSource({ features: worldFeatures })

export const worldStyle = new Style({
  fill: new Fill({
    color: 'rgba(256, 256, 256, 0.8)'
  })
})

export const worldLayer = new VectorLayer({
  source: worldVectorSource,
  style: worldStyle
})
