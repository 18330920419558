import debounce from 'debounce'
import React, { useEffect, useState } from 'react'
import isDefined from '../../../lib/utils/is-defined'
import Map from '../../Common/Map'

const MobileMap = () => {
  const [mapHeight, setMapHeight] = useState(null)

  useEffect(() => {
    function handleResize () {
      const footerPosition = (document.querySelector('footer')?.getBoundingClientRect() || { y: 0 }).y
      const headerHeight = (document.querySelector('header')?.getBoundingClientRect() || { height: 0 }).height
      setMapHeight(footerPosition - headerHeight)
    }

    window.addEventListener('resize', debounce(handleResize, 200))
    handleResize()
  }, [])

  if (!isDefined(mapHeight)) {
    return null
  }

  return <Map {...{ height: mapHeight, useOSM: false, mobile: true }}/>
}

export default MobileMap
