import clsx from 'clsx'
import React, { useState } from 'react'
import styled from 'styled-components'
import getTheme from '../../../lib/theme'
import IconExpand from '../../../assets/icon_expand.jpg'
import Image from '../../../lib/components/Image'

const StyledMapLegend = styled.div`
  position: absolute;
  bottom:  ${getTheme().spacing * 4}px;
  right:  ${getTheme().spacing * 2}px;
  background: white;
  padding: ${getTheme().spacing * 2}px;
  border-radius: ${getTheme().spacing}px;
  ${({ open }) => {
    if (!open) {
      return 'box-shadow: 1px 1px 3px #ddd;'
    }

    return 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);'
  }}
  
  cursor: pointer;

  &.closed {
    padding: ${getTheme().spacing}px;
  }

  .legend-bar {
    width: 25%;
    height: ${getTheme().spacing * 4}px;
    background: red;
  }
  .legend-bar-container {
    display: flex;
  }
  .legend-text {
    font-size: ${getTheme().fontSize * 1.5}px;
  }
  .legend-title {
    &.closed {
      display: flex;
      align-items: center;
      font-size: ${getTheme().fontSize * 1.25}px;
    }
  }
  .expand-icon {
    margin-left: ${getTheme().fontSize}px;
    width: ${getTheme().fontSize * 1.25}px;
    height: ${getTheme().fontSize * 1.25}px;
  }
`
const MapLegend = ({ mobile, ...rest }) => {
  const [open, setOpen] = useState(!mobile)

  return <StyledMapLegend className={clsx(open && 'col-xs-8 col-lg-3', !open && 'closed')}{...rest} onClick={() => setOpen(!open)}>
    <div className={clsx('col-xs-12 legend-title', !open && 'closed')} style={{ padding: 0, paddingBottom: open ? getTheme().spacing : 0 }}>Legende {!open && <Image className="expand-icon" src={IconExpand} />}</div>

    {open && <><div className="col-xs-12 legend-bar-container" style={{ padding: 0 }}>
      <div className="legend-bar" style={{ background: '#58AC32' }}></div>
      <div className="legend-bar" style={{ background: '#79BB54' }}></div>
      <div className="legend-bar" style={{ background: '#99C978' }}></div>
      <div className="legend-bar" style={{ background: '#B6D99E' }}></div>
      <div className="legend-bar" style={{ background: '#D5E7C5' }}></div>
    </div>
    <div className="col-xs-12 legend-text" style={{ padding: 0, paddingTop: getTheme().spacing }}>
      <div className="col-xs-2" style={{ padding: 0, textAlign: 'left' }}>100%</div>
      <div className="col-xs-8" style={{ padding: 0, textAlign: 'center' }}>Erneuerbare Energien</div>
      <div className="col-xs-2" style={{ padding: 0, textAlign: 'right' }}>0%</div>
    </div></>}

  </StyledMapLegend>
}

export default MapLegend
