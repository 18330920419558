import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../../lib/context/AppContext'
import getTheme from '../../../lib/theme'
import { CircularProgressbar } from 'react-circular-progressbar'
import Paper from '../../../lib/components/Paper'
import BarChart from '../../../lib/components/BarChart'
import localizeNumber from '../../../lib/utils/localize-number'
import AppStoreInfo from '../AppStoreInfo'
import isDefined from '../../../lib/utils/is-defined'
// import responseEnergyMix from '../../../assets/response_energy-mix.json'
import moment from 'moment'
import getConfig from '../../../lib/config'

const StyledSidebar = styled.div`
 
  padding: ${({ mobile }) => (!mobile && getTheme().spacing * 4) || getTheme().spacing}px;
  .districtName{
    line-height: ${getTheme().spacing * 3.375}px;
    margin-bottom: ${getTheme().spacing * 2}px;
    font-size: ${getTheme().fontSize * 2.625}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .headline{
    font-size: ${getTheme().fontSize * 3.375}px;
    line-height: ${getTheme().spacing * 4}px;
    margin-bottom: ${getTheme().spacing * 2}px;
    text-align: center;
  }

  .energymix-text,
  .energymix-co2-value {
    font-family: 'Polo22Krftg';
    text-align: center;
  }

  .subheadline2 {
    text-align: center;
  }
  
`

const transformForecast = forecast => {
  const today = moment().format('YYYY-MM-DD')

  const onlyToday = forecast.values.filter(({ time }) => time.match(new RegExp(`^${today}`)))
  const sameHours = onlyToday.map(({ time, ...rest }) => {
    const tempDate = moment(time).toDate()
    return { time: tempDate.getHours(), ...rest }
  })
  const averaged = sameHours.reduce((acc, curr, i, all) => {
    const alreadyAveraged = acc.some(({ x }) => curr.time + 1 === x)
    if (alreadyAveraged) {
      return [...acc]
    }
    const sameByHours = all.filter(({ time }) => curr.time === time)
    const average = sameByHours.reduce((accHours, currHours, resultHours) => currHours.energyMix + accHours, 0) / sameByHours.length
    return [...acc, { x: curr.time + 1, y: average }]
  }, [])
  return averaged
}

const texts = [
  'Verschiebe deinen Energieverbrauch, wenn möglich.',
  'Jetzt ist die beste Zeit um Energie zu verbrauchen.',
  'Bitte warten ...'
]

const Sidebar = ({ mobile }) => {
  const { currentCounty, currentApi, currentForecast, setCurrentForecast, currentStage } = useAppContext()
  const [loadingState, setLoadingState] = useState()

  useEffect(() => {
    if (!currentApi) {
      return
    }
    if (!currentCounty) {
      return
    }
    if (!currentStage) {
      return
    }

    async function fetchData () {
      const options = { headers: { 'Ocp-Apim-Subscription-Key': getConfig().api_keys[currentStage] } }

      try {
        setLoadingState('loading')
        setCurrentForecast([])
        const url = currentApi + `/api/forecast/v1/energy-mix/${currentCounty.kreis_nutscode}/0`
        const response = await window.fetch(url, options).then(res => {
          if (!res.ok) {
            throw Error(res.statusText)
          }
          return res.json()
        })
        // const response = responseEnergyMix
        if (!response.values.length) {
          return setLoadingState('error')
        }
        const transformedForecast = transformForecast(response)
        setCurrentForecast(transformedForecast)

        setLoadingState('success')
      } catch (e) {
        console.error(e)
        setLoadingState('error')
      }
    }

    fetchData()
  }, [currentApi, currentCounty, currentStage])

  return <StyledSidebar {...{ mobile }}>
        <div className="districtName" title={currentCounty && currentCounty.krs_name || ''}>{currentCounty && `${currentCounty.krs_name}` || 'Region wird geladen ...'}</div>
        <div className="headline">Live Energie-Mix</div>
        <Paper style={{ background: 'none', marginBottom: getTheme().spacing, display: 'flex', padding: getTheme().spacing * 2 }} >
          <div className="col-xs-5" style={{ padding: 0 }}>
            <CircularProgressbar strokeWidth={5} value={currentCounty.energyMix || 0} text={`${localizeNumber(currentCounty.energyMix || 0, 0)}%` }
            styles={{
              root: {
                width: '100%',
                height: '100%'
              },
              path: {
                stroke: currentCounty.energyMix >= 60 ? '#A6CF7E' : '#0091BB',
                width: 0
              },
              text: {
                fontSize: getTheme().fontSize * 4,
                fontFamily: 'Polo22Buch',
                fontWeight: '400',
                fill: '#333'
              },
              trail: {
                height: 0,
                with: 0

              }
            }}/>
            </div>
            <div className="col-xs-7 energymix-text" style={{ fontSize: getTheme().fontSize * 2.75, display: 'flex', alignItems: 'center' }}>
              {isDefined(currentCounty.energyMix) ? currentCounty.energyMix < 60 ? texts[0] : texts[1] : texts[2]}
          </div>
        </Paper>
        <Paper style={{
          background: 'none',
          fontWeight: 'bold',
          padding: getTheme().spacing * 2.5,
          marginBottom: getTheme().spacing * 3
        }}>
          <div className="col-xs-12 energymix-co2-value" style={{ padding: 5, fontSize: getTheme().fontSize * 4.125, textAlign: 'center' }}>
            {localizeNumber(currentCounty.co2, 0) || 0}g / kWh
          </div>
          <div className="col-xs-12 energymix-co2-legend" style={{ padding: 5, textAlign: 'center', fontSize: getTheme().fontSize * 1.75 }}>
            CO2 Emissionen für unsere Region
          </div>
        </Paper>
        <div className="headline"style={{ fontSize: getTheme.fontSize * 4 }}>Regionale Vorhersage</div>
        <div className="subheadline2"style={{ fontSize: getTheme.fontSize * 4 }}>Heutige Ökozeiten</div>
        <BarChart data={currentForecast} status={loadingState}/>
        {!mobile && <AppStoreInfo />}
  </StyledSidebar>
}

export default Sidebar
