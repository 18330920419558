import { Fill, Stroke, Style } from 'ol/style'
import isDefined from '../../../lib/utils/is-defined'

export const GermanyHoleStyles = {
  MultiPolygon: new Style({
    fill: new Fill({
      color: 'rgba(256, 256, 256, 0.8)'
    })
  })
}

export const GermanyCountiesStyles = {
  MultiPolygon: new Style({
    fill: new Fill({
      color: '#79BB54'
    }),
    stroke: new Stroke({
      color: '#656565',
      width: 1
    })
  }),
  Polygon: new Style({
    fill: new Fill({
      color: '#D5E7C5'
    }),
    stroke: new Stroke({
      color: '#656565',
      width: 1
    })
  })
}

const mixFunction = mix => {
  if (!isDefined(mix)) {
    return 'white'
  }
  if (mix < 20) {
    return '#D5E7C5'
  }
  if (mix < 40) {
    return '#B6D99E'
  }
  if (mix < 50) {
    return '#99C978'
  }
  if (mix < 80) {
    return '#79BB54'
  }

  return '#58AC32'
}

export const GermanyCountiesStylesDynamic = function (feature) {
  const mix = feature.get('energyMix')

  const style = new Style({
    fill: new Fill({
      color: mixFunction(mix)
    }),
    stroke: new Stroke({
      color: '#656565',
      width: 1
    })
  })

  return style
}

export const hoverStyle = new Style({
  fill: new Fill({
    color: '#eeeeee'
  })
})
export const selectStyle = new Style({
  fill: new Fill({
    color: '#eeeeee'
  })
})
