import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import counties from '../../../../assets/germany_counties_cleaned.json'
import isDefined from '../../../../lib/utils/is-defined'

const countiesStylesDynamic = function (feature) {
  const mix = feature.get('energyMix')

  if (!isDefined(mix)) {
    return new Style({
      fill: new Fill({
        color: '#fff'
      }),
      stroke: new Stroke({
        color: '#656565',
        width: 1
      })
    })
  }
}
export const getCountiesFeatures = () => {
  return new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(counties)
}
export const getCountiesLayer = features => {
  if (!isDefined(features)) {
    features = getCountiesFeatures()
    features.map(feature => {
      feature.set('energyMix', null)
      feature.set('co2', null)

      return feature
    })
  }

  const countiesSource = new VectorSource({ features: features })
  const countiesLayer = new VectorLayer({
    source: countiesSource,
    style: countiesStylesDynamic
  })
  countiesLayer.set('name', 'counties')
  countiesLayer.className_ = 'county-layer'

  return { features, countiesSource, countiesLayer }
}
