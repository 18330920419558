import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Image from '../../../lib/components/Image'
import withRouter from '../../../lib/components/routing/Router/with-router'
import mapIcon from '../../../assets/mapicon.svg'
import infoIcon from '../../../assets/infoicon.svg'
import regionIcon from '../../../assets/regionicon.svg'
import getTheme from '../../../lib/theme'

const StyledNavigationFooter = styled.div`
  flex: none;

  footer{
    display: flex;
    border-top: 1px solid #D7D7D7;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    bottom: env(safe-area-inset-bottom);
    background: white;
    
    .tab-item {
      text-align: center;
      cursor: pointer;
      color: #989898;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${getTheme().spacing}px;

      &.active {
        color: #000;
      }
      .footer-icon {
        width: 20px;
        height: 20px;
        margin-bottom: ${getTheme().spacing}px;
      }
    }
  }
`

const NavigationFooter = withRouter(({ navigate, activeRoute: { path } }) => {
  const [height, setHeight] = useState(null)

  useEffect(() => {
    const clientHeight = document.querySelector('#footer').getBoundingClientRect().height
    setHeight(clientHeight)
  })

  return <StyledNavigationFooter>
    <div className="spacer" style={{ height }}></div>
    <footer id="footer">
      <div className={clsx('col-xs-4 tab-item', path && path.match(/region/) && 'active')} onClick={() => navigate('/region')}>
      <Image className={'footer-icon'} src={regionIcon}></Image>
        Region
      </div>
      <div className={clsx('col-xs-4 tab-item', path && path.match(/map/) && 'active')} onClick={() => navigate('/map')}>
        <Image className={'footer-icon'} src={mapIcon}></Image>
        Karte
        </div>
      <div className={clsx('col-xs-4 tab-item', path && path.match(/info/) && 'active')} onClick={() => navigate('/info')}>
      <Image className={'footer-icon'} src={infoIcon}></Image>
        Info
      </div>
    </footer>
  </StyledNavigationFooter>
})

export default NavigationFooter
