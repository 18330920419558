import '../../assets/fonts/fonts.css'

const getTheme = () => {
  const darkMode = false// localStorage.getItem('darkMode') === 'true'
  const maxWidth = 1920
  const sm = 576
  const md = 768
  const lg = 992
  const xl = 1200
  const spacing = 8
  const fontSize = 8

  let color = '#fafafa'
  let primary = '#24292e'
  let primaryShade = '#F6F8FA'
  let shadow = '#dddddd'
  let background = '#FFFFFF'
  let highlight = '#fff'

  if (darkMode) {
    color = '#24292e'
    highlight = '#6e6e6e'
    primary = '#fafafa'
    primaryShade = '#181B1F'
    shadow = '#1C1C1C'
    background = '#F8F8F8'
  }
  const bag = {
    yellow: '#FFD61D',
    grey: '#F0F0F0',
    blue: '#0091BB',
    blue2: '#008EB0',
    darkBlue: '#005870'
  }

  return {
    bag,
    breakpoints: {
      sm,
      md,
      lg,
      xl
    },
    highlight,
    maxWidth,
    background,
    primary,
    primaryShade,
    shadow,
    color,
    text: {
      color: primary
    },
    dropdown: {
      background: '#181B1F',
      border: highlight,
      fontSize: fontSize,
      highlight
    },
    fontFamily: 'POLO11',
    fontSize: fontSize,
    spacing,
    image: {},
    paper: { shadow: highlight, color },
    contextMenu: {
      background: color,
      highlight,
      shadow,
      color: primary,
      hover: {
        background: '#6e6e6e'
      }
    },
    node: {
      play: {
        color: '#4285F4'
      }
    },
    pageTitle: {
      background: bag.yellow,
      color: '#502B59'
    },
    popover: {
      background: color,
      spacing,
      highlight,
      shadow: highlight,
      color: primary,
      hover: {
        background: '#6e6e6e'
      }
    },
    button: {
      primary: {
        background: primary,
        shadow,
        color,
        hover: {
          background: '#6e6e6e',
          color
        }
      },
      secondary: {
        shadow,
        background: color,
        color: primary,
        hover: {
          background: '#6e6e6e',
          color: primary
        }
      }
    }
  }
}

export default getTheme
