import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import ResponsiveGrid, { getCurrentBreakpoint } from 'wnderlvst-grid'
import { AppWrapper, useAppContext } from '../lib/context/AppContext'
import Router from '../lib/components/routing/Router'
import Route from '../lib/components/routing/Route'
import getTheme from '../lib/theme'
import styled from 'styled-components'
import Navbar, { navbarHeight } from './Desktop/Navbar'
import MobileNavbar from './Mobile/MobileNavbar'
import Map from './Common/Map'
import RegionInfo from './Common/RegionInfo'
import NavigationFooter from './Mobile/NavigationFooter'
import MobileMap from './Mobile/MobileMap'
import MobileRegion from './Mobile/MobileRegion'
import MobileInfo from './Mobile/MobileInfo'
import debounce from 'debounce'
import DesktopFooter from './Desktop/DesktopFooter'
import 'react-circular-progressbar/dist/styles.css'
import clsx from 'clsx'
import isDefined from '../lib/utils/is-defined'
import Polo22Buch from '../assets/fonts/Polo22/Polo22-Buch.woff2'
import Polo22Krftg from '../assets/fonts/Polo22/Polo22-Krftg.woff2'
import Polo22Leicht from '../assets/fonts/Polo22/Polo22-Leicht.woff2'
import queryString from 'query-string'

import 'core-js'

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;

  &.desktop {
    height: 100vh;
  }

  .content {
    padding-top: ${props => props.navbarDisabled ? 0 : navbarHeight}px;
    height: 100%;
    width: 100%;
    max-width: ${getTheme().maxWidth}px;
  }
  #map-container{
    position: relative;
  }
  
  &.fade-in, .fade-in {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }
  .no-padding { 
    padding: 0;
  }
  .center-text {
    text-align: center;
  }
  @font-face {
    font-family: 'Polo22Buch';
    src: url(${Polo22Buch}) format('woff2');
  }
  @font-face {
    font-family: 'Polo22Krftg';
    src: url(${Polo22Krftg}) format('woff2');
  }
  @font-face {
    font-family: 'Polo22Leicht';
    src: url(${Polo22Leicht}) format('woff2');
  }
  
  * {
    font-family: 'Polo22Buch';
    font-weight: 400;
    font-style: normal;
    color: #333;
    letter-spacing: 0.01em !important;
  }
`

const App = () => {
  const { setCurrentApi, desktopFooterHeight, setCurrentStage } = useAppContext()
  const [currentBreakpoint, setCurrentBreakpoint] = useState(getCurrentBreakpoint())
  const [fadeIn, setFadeIn] = useState(false)

  const qsp = queryString.parse(global.location.search)

  useEffect(() => {
    function handleResize () {
      const breakpoint = getCurrentBreakpoint()
      if (currentBreakpoint === breakpoint) {
        return
      }

      setCurrentBreakpoint(breakpoint)
    }

    window.addEventListener('resize', debounce(handleResize, 200))
    setFadeIn(true)
  }, [])

  useEffect(() => {
    async function fetchData () {
      try {
        const response = await window.fetch('//' + window.location.host + '/api.json').then(data => data.json())
        const apiEndpoint = response.api_endpoint
        const stage = response.stage
        setCurrentApi(apiEndpoint)
        setCurrentStage(stage)
      } catch (e) {
        console.error(e)
      }
    }

    fetchData()
  }, [])

  if (currentBreakpoint !== 'sm' && currentBreakpoint !== 'xs') {
    return (<StyledApp className={clsx(fadeIn && 'fade-in', 'desktop')} navbarDisabled={qsp.header === 'disabled'}>
        {qsp.header !== 'disabled' && <Navbar />}
        <div className="content" style={{ paddingBottom: desktopFooterHeight }}>
          <div className="col-md-6 col-lg-3" style={{
            padding: 0,
            height: '100%',
            background: '#FAFAFA',
            overflow: 'auto'
          }}>
            <RegionInfo/>
            </div>
          <div id="map-container" className="col-md-6 col-lg-9" style={{ height: '100%', padding: 0 }}>
            {isDefined(desktopFooterHeight) && <Map />}
          </div>
        </div>
        {qsp.footer !== 'disabled' && <DesktopFooter />}

 </StyledApp>)
  }

  return (<StyledApp className={clsx(fadeIn && 'fade-in')}>
    {qsp.header !== 'disabled' && <MobileNavbar />}
    <Route path={'/region'} exact={true} Component={MobileRegion}></Route>
    <Route path={'/'} exact={true} redirect={'/map'}></Route>
    <Route path={'/map'} exact={true} Component={MobileMap}></Route>
    <Route path={'/info'} exact={true} Component={MobileInfo}></Route>
    <NavigationFooter />
  </StyledApp>)
}

document.querySelector('html').style.background = '#FAFAFA'
document.querySelector('body').style.margin = 0

ReactDOM.render(
  <Router>
    <AppWrapper>
          <ResponsiveGrid>
          <App />
        </ResponsiveGrid>
      </AppWrapper>
  </Router>,
  document.getElementById('root')
)
