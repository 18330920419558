import React, { useEffect, useState } from 'react'
import isDefined from '../../../lib/utils/is-defined'
import OlMap from 'ol/Map'
import OlView from 'ol/View'
import OlLayerTile from 'ol/layer/Tile'
import OlSourceOSM from 'ol/source/OSM'
import 'ol/ol.css'
import { useAppContext } from '../../../lib/context/AppContext'
import { Overlay } from 'ol'
import MapLegend from '../MapLegend'
import MapOverlay from '../MapOverlay'
import Styled from 'styled-components'
import getOverlayOffsets from './getOverlayOffsets'
import { GermanyCountiesStylesDynamic, hoverStyle, selectStyle } from './styles'
import { extent, germanyRoughExtend, germanyRoughVectorSource } from './layers/germanyRough'
import { worldLayer } from './layers/world'
import { getCountiesLayer } from './layers/germanyCounties'
import deepClone from 'deep-clone'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Attribution from 'ol/control/Attribution'
import StatusIndicator from '../StatusIndicator'
import LiveIndicator from '../LiveIndicator'
import getConfig from '../../../lib/config'
import Response from '../../../assets/test-api-response.json'

const StyledMap = Styled.div`
  position: relative;
.legend-overlay-container {
  transform: none !important;
  width: 100%;
  height: 100%;
}

.county-layer{
  animation-duration: 0.3s;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`
const startCountyNutsCode = 'DE232'

const Map = ({ height = '100%', useOSM = true, mobile = false }) => {
  const [map, setMap] = useState(null)
  const [zoom, setZoom] = useState(1)
  const [center, setCenter] = useState([0, 0])
  const [loadingState, setLoadingState] = useState('loading')
  const { currentCounty, setCurrentCounty, energyMix, setEnergyMix, currentApi, currentStage } = useAppContext()

  useEffect(() => {
    const MarkerOverlay = new Overlay({
      position: null,
      element: document.getElementById('map-overlay'),
      stopEvent: false
    })
    const LegendOverlay = new Overlay({
      className: 'legend-overlay-container',
      element: document.getElementById('legend-overlay'),
      stopEvent: false
    })

    const { countiesLayer } = getCountiesLayer()
    const view = new OlView({
      center,
      zoom,
      enableRotation: false,
      extent: germanyRoughExtend,
      showFullExtent: true,
      preload: Infinity
    })

    const tempMap = new OlMap({
      target: 'map',
      controls: [new Attribution()],
      layers: [
        new OlLayerTile({
          source: new OlSourceOSM()
        }),
        worldLayer,
        countiesLayer
      ].filter(val => val !== false),
      view
    })

    tempMap.addOverlay(MarkerOverlay)
    tempMap.addOverlay(LegendOverlay)

    germanyRoughVectorSource.once('change', function (evt) {
      if (germanyRoughVectorSource.getState() === 'ready') {
        map.getView().fit(extent, map.getSize())
      }
    })

    const viewExtent = tempMap.getView().calculateExtent()
    LegendOverlay.setPosition([viewExtent[2], viewExtent[1]])

    let highlighted = null
    let selected = null
    tempMap.on('pointermove', function (e) {
      if (highlighted !== null && highlighted !== selected) {
        highlighted.setStyle(undefined)
        highlighted = null
      }
      tempMap.forEachFeatureAtPixel(e.pixel, function (f) {
        highlighted = f
        hoverStyle.getFill().setColor(f.get('COLOR') || '#0091BB')
        f.setStyle(hoverStyle)
        return true
      }, {
        layerFilter: function (layer) {
          return layer.get('name') === 'counties'
        }
      })
    })

    tempMap.on('click', function (e) {
      if (selected !== null) {
        MarkerOverlay.setPosition(null)
        selected.setStyle(undefined)
        selected = null
      }

      tempMap.forEachFeatureAtPixel(e.pixel, function (f) {
        selected = f
        selectStyle.getFill().setColor(f.get('COLOR') || '#0091BB')
        f.setStyle(selectStyle)
        setCurrentCounty(f.values_)
        MarkerOverlay.setOffset([0, 0])
        MarkerOverlay.setPositioning('top-left')
        MarkerOverlay.setPosition(e.coordinate)

        const offset = getOverlayOffsets(tempMap, MarkerOverlay)
        if (offset[1] > 0) {
          MarkerOverlay.setPositioning('bottom-center')
        }

        MarkerOverlay.setOffset(offset)

        return true
      }, {
        layerFilter: function (layer) {
          return layer.get('name') === 'counties'
        }
      })
    })

    setMap(tempMap)
  }, [])

  useEffect(() => {
    if (!isDefined(energyMix)) {
      return
    }
    if (!isDefined(map)) {
      return
    }

    const oldCountyLayer = map.getAllLayers().find(({ values_: { name } }) => name === 'counties')
    const countySource = oldCountyLayer.getSource()
    const countyFeatures = countySource.getFeatures()

    const newCountyFeatures = countyFeatures.map(feature => {
      const targetFeature = energyMix.data.find(({ nuts3, ...rest }) => feature.values_.kreis_nutscode === nuts3)

      if (!isDefined(targetFeature)) {
        return feature
      }

      feature.set('energyMix', targetFeature.energyMix)
      feature.set('co2', targetFeature.co2)

      return feature
    })

    const germanyCountiesSource = new VectorSource({ features: newCountyFeatures })

    const germanyCountiesLayer = new VectorLayer({
      source: germanyCountiesSource,
      style: GermanyCountiesStylesDynamic
    })
    germanyCountiesLayer.className_ = 'county-layer'
    germanyCountiesLayer.set('name', 'counties')
    map.addLayer(germanyCountiesLayer)
    const startCounty = (newCountyFeatures.find(({ values_ }) => values_.kreis_nutscode === startCountyNutsCode) || {}).values_

    map.removeLayer(oldCountyLayer)
    setCurrentCounty(deepClone(currentCounty || startCounty))
  }, [energyMix])

  useEffect(() => {
    if (isDefined(map)) {
      map.render()
    }
  }, [map, height])

  useEffect(() => {
    if (!isDefined(currentApi)) {
      return
    }
    if (!isDefined(currentStage)) {
      return
    }

    async function fetchData () {
      try {
        const url = currentApi + '/api/forecast/v1/all-regions' // eslint-ignore-line
        const options = { headers: { 'Ocp-Apim-Subscription-Key': getConfig().api_keys[currentStage] } }

        const response = await window.fetch(url, options).then(res => {
          if (!res.ok) {
            throw Error(res.statusText)
          }
          return res
        })

        const data = await response.json()

        if (!data.length) {
          return setLoadingState('error')
        }

        const date = response.headers.get('last-modified')
        // const response = Response

        setLoadingState('completed')
        setEnergyMix({ date, data })
      } catch (e) {
        console.error(e)
        setLoadingState('error')
      }
    }

    fetchData()
  }, [currentApi, currentStage])

  return (
    <StyledMap style={{ width: '100%', height }} >
      <div id="map" style={{ width: '100%', height }} />
      <MapLegend id="legend-overlay" mobile={mobile} />
      <MapOverlay id="map-overlay" mobile={mobile} currentCounty={currentCounty}/>
      {(loadingState === 'loading' || loadingState === 'error') &&
        <StatusIndicator status={loadingState} text={(loadingState === 'error' && 'Aufgrund eines technischen Problems konnten wichtige Daten nicht geladen werden.') || null}/>}
      {loadingState === 'completed' && <LiveIndicator />}
    </StyledMap>
  )
}

export default Map
