import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import withRouter from '../Router/with-router'
import { getParamsFromLocation, doesLocationMatchesPath, doesLocationMatchesSubpath, getPathWithParams } from './utils'

export default compose(
  withRouter,
  withPropsOnChange(
    ['location', 'path', 'Component', 'navigate', 'redirect', 'exact'],
    ({ location, path, navigate, redirect, exact = true }) => {
      const locationMatchesPath = doesLocationMatchesPath({ location, path })
      if (exact && !locationMatchesPath) {
        return { isThisTheRoute: false, route: { path: null, location: null, params: {} } }
      }

      const locationMatchesSubpath = doesLocationMatchesSubpath({ location, path })
      if (!exact && !locationMatchesSubpath) {
        return { isThisTheRoute: false, route: { path: null, location: null, params: {} } }
      }

      if (redirect) {
        navigate(redirect)
        return { isThisTheRoute: false, route: { path: null, location: null, params: {} } }
      }

      const params = getParamsFromLocation({ location, path })
      const relativeLocation = getPathWithParams({ location, path })
      const route = { location: relativeLocation, params, path }

      return { isThisTheRoute: true, route }
    }
  ),
  withPropsOnChange(
    ['isThisTheRoute', 'route'],
    ({ isThisTheRoute, route, setActiveRoute }) => isThisTheRoute && setActiveRoute(route)
  )
)(({ isThisTheRoute, Component, route }) => (isThisTheRoute && <Component {...{ route }} />) || null)
