import React from 'react'
import styledComponents from 'styled-components'
import getTheme from '../../../lib/theme'
import RegionInfo from '../../Common/RegionInfo'

const StyledMobileRegion = styledComponents.div`
padding: ${getTheme().spacing}px;
`

const MobileMap = () => {
  return <StyledMobileRegion><RegionInfo mobile={true}/></StyledMobileRegion>
}

export default MobileMap
