import React from 'react'
import styledComponents from 'styled-components'
import getTheme from '../../../lib/theme'
import AppStoreInfo from '../../Common/AppStoreInfo'
import { LinkExternalIcon } from '../../../lib/components/Icons'

const StyledMobileRegion = styledComponents.div`
padding: ${getTheme().spacing * 2}px;
.links {
  display: flex;
  flex-direction: column;
  margin-bottom: ${getTheme().spacing * 4}px;
  .link-item {
    padding-bottom: ${getTheme().spacing * 2}px;
    padding-top: ${getTheme().spacing * 2}px;
    color: #333;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
`

const MobileMap = () => {
  return <StyledMobileRegion>
    <div className="links">
      <a href="https://www.bayernwerk.de/de/ueber-uns/disclaimer.html" className="link-item" role="link" target="_blank" rel="noreferrer">AGB<LinkExternalIcon style={{ width: getTheme().fontSize * 3 }}/></a>
      <a href="https://www.bayernwerk.de/de/ueber-uns/impressum.html" className="link-item" role="link" target="_blank" rel="noreferrer">Impressum<LinkExternalIcon style={{ width: getTheme().fontSize * 3 }}/></a>
      <a href="https://www.bayernwerk.de/de/ueber-uns/datenschutz.html" className="link-item" role="link" target="_blank" rel="noreferrer">Datenschutz<LinkExternalIcon style={{ width: getTheme().fontSize * 3 }}/></a>
      <a href="licenses.txt" className="link-item">Lizenzen<LinkExternalIcon style={{ width: getTheme().fontSize * 3 }} role="link" target="_blank" rel="noreferrer"/></a>
    </div>
    <AppStoreInfo />
  </StyledMobileRegion>
}

export default MobileMap
