import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Image from '../../../lib/components/Image'
import OekoheldLogo from '../../../assets/BW_appicon_oekoheld_RGB_192px_192px_gelb.jpg'
import BAGLogo from '../../../assets/Logo BAG new.jpg'
import getTheme from '../../../lib/theme'

const height = 83
const StyledNavbar = styled.header`
.navbar-spacer{
  height: ${height}px;
}
.header {
  flex: none;
  padding: ${getTheme().spacing * 4}px;
  display: flex;
  align-items: center;
  top: env(safe-area-inset-top);
  left: 0;
  background: ${getTheme().background};
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  height: ${height}px;

  .headline {
    margin: 0;
    margin-left: 32px;
    font-size: 32px;
    margin-right: auto;
    font-family: 'Polo22Krftg';
  }

  .logo-container {
    width: 60px;
    height: 60px;
  }

  .bag-logo-container {
    width: 172px;
  }
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
}
`

const Navbar = () => {
  return <StyledNavbar>
        <div className="navbar-spacer"/>
        <div className="header">
          <div className="logo-container"><Image src={OekoheldLogo}/></div>
          <h1 className="headline">Energiekarte</h1>
        </div>
  </StyledNavbar>
}

export default (Navbar)
