import React from 'react'
import { compose, withStateHandlers, lifecycle } from 'recompose'
import clsx from 'clsx'
import styled from 'styled-components'

const StyledImage = styled.img`
  transition: opacity 0.3s ease-in-out;
  display: flex;
  width: 100%;
  height: auto;

  &.loading {
    opacity: 0;
  }
  &.success {
    opacity: 1;
  }
`

export default compose(
  withStateHandlers(
    () => ({
      loading: true,
      success: false,
      error: false
    }),
    {
      setSuccess: () => () => ({ loading: false, success: true, error: false }),
      setError: () => () => ({ loading: false, success: false, error: true })
    }
  ),
  lifecycle({
    componentDidMount () {
      const { src, setSuccess, setError } = this.props
      const image = new Image() // eslint-disable-line
      image.onload = setSuccess
      image.onerror = setError
      image.src = src
    }
  })
)(({ src, loading, succes, error, className, style, onClick }) => (
  <StyledImage
    onClick={onClick}
    src={src}
    className={clsx(loading && 'loading', succes && 'success', error && 'error', className)}
    style={style}
  ></StyledImage>
))
