import getTheme from '../../../lib/theme'

const getOverlayOffsets = function (mapInstance, overlay) {
  const overlayRect = overlay.getElement().getBoundingClientRect()
  const mapRect = mapInstance.getTargetElement().getBoundingClientRect()
  const margin = getTheme().spacing * 2
  // if (!ol.extent.containsExtent(mapRect, overlayRect)) //could use, but need to convert rect to extent

  const offsetLeft = overlayRect.left - mapRect.left
  const offsetRight = mapRect.right - overlayRect.right
  const offsetTop = overlayRect.top - mapRect.top
  const offsetBottom = mapRect.bottom - overlayRect.bottom

  const delta = [0, 0]
  if (offsetLeft <= 0) {
    // move overlay to the right
    delta[0] = margin - offsetLeft
  } else if (offsetRight < 0) {
    // move overlay  to the left
    delta[0] = -(Math.abs(offsetRight) + margin)
  }
  if (offsetTop <= 0) {
    // will change the positioning instead of the offset to move overlay down.
    delta[1] = margin - offsetTop
  } else if (offsetBottom < 0) {
    // move overlay up - never happens if bottome-center is default.
    delta[1] = -(Math.abs(offsetBottom) + margin)
  }
  return (delta)
}
export default getOverlayOffsets
