import React, { useEffect } from 'react'
import styled from 'styled-components'
import Image from '../../../lib/components/Image'
import OekoheldLogo from '../../../assets/BW_appicon_oekoheld_RGB_192px_192px_gelb.jpg'
import BAGLogo from '../../../assets/Logo BAG new.jpg'
import getTheme from '../../../lib/theme'

export const navbarHeight = 83
const StyledNavbar = styled.div`
.content-container {
  z-index: 1;
  padding: ${getTheme().spacing * 4}px;
  display: flex;
  align-items: center;
  height: ${navbarHeight}px;
  position: absolute;
  top: 0;
  left: 0;
  top: env(safe-area-inset-top);
  background: ${getTheme().background};
  width: 100%;

  .headline {
    font-family: 'Polo22Krftg';
    margin: 0;
    margin-left: 32px;
    font-size: 32px;
    margin-right: auto;
  }
  .logo-container {
    width: 60px;
    height: 60px;
  }
  .bag-logo-container {
    width: 172px;
  }
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
}
`

const Navbar = function() {
  return <StyledNavbar>
    <div className="content-container">
        <div className="logo-container"><Image src={OekoheldLogo}/></div>
        <h1 className="headline">Energiekarte</h1>
        <div className="bag-logo-container"><Image src={BAGLogo}/></div>
      </div>
  </StyledNavbar>
}

export default Navbar
